import React from "react"
import { useTranslation } from "react-i18next"
import NavBar from "../components/layout/NavBar"
import LOGO from '../assets/wheat_official.svg'
import LOGO1 from '../assets/wheat_official.png'
import { Helmet } from "react-helmet"

const Brand = () => {
    const { t } = useTranslation()
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title>{t('brand_title')} | Wheat Discord Bot</title>
                <meta
                    name="description"
                    content={t('brand_desc')}
                />
                <link rel="canonical" href="https://www.wheatbot.xyz/brand" />

                <meta property="og:site_name" content="Wheat Discord Bot" />
                <meta property="og:url" content="https://www.wheatbot.xyz/brand" />
                <meta property="og:title" content="Brand Guidelines | Wheat Discord Bot" />
                <meta property="og:image" content={process.env.REACT_APP_URL_IMAGE} />
                <meta property="og:description" content="Official Brand Guidelines of Wheat." />
            </Helmet>

            <NavBar style="white" />

            <div className="px-10 lg:px-32 py-20 font-text bg-white ">
                <div className="text-4xl md:text-6xl font-black font-extra">
                    {t('brand_p1')}
                </div>
            </div>

            <div className="flex flex-col bg-wheat-black px-10 lg:px-32 py-20">
                <div className="text-4xl md:text-6xl font-black font-extra text-white">
                    {t('brand_p2')}
                </div>

                <div className="flex justify-center flex-1 pt-10">
                    <div className="">
                        <img src={LOGO} alt="" className="w-3/4 h-auto mx-auto py-5 px-10 bg-white border-2 border-wheat-yellow2" />
                    </div>

                    <div className="flex flex-col justify-evenly">
                        <a href={LOGO1} download='wheat-logo'>
                            <div className={`border-4 border-wheat-black font-large font-semibold text-base md:text-xl rounded-md lg:px-8 py-3 transition duration-500 text-wheat-black bg-wheat-yellow3`}>
                                {t('brand_p3')}
                            </div>
                        </a>

                        <a href={LOGO} download='wheat-logo'>
                            <div className={`border-4 border-wheat-black font-large font-semibold text-base md:text-xl rounded-md lg:px-8 py-3 transition duration-500 text-wheat-black bg-wheat-yellow3`}>
                                {t('brand_p4')}
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div className="flex flex-col bg-white h-screen px-10 lg:px-32 py-20">
                <div className="text-4xl md:text-6xl font-black font-extra text-wheat-black">
                    {t('brand_p5')}
                </div>

                <div className="flex-1 flex pt-5 font-large">
                    <div className="flex-1 flex bg-yellow-100 items-center justify-center">
                        <div className="text-wheat-black ">#FEF3C6</div>
                    </div>
                    <div className="flex-1 flex bg-wheat-yellow1 items-center justify-center">
                        <div className="text-wheat-black ">#FFC640</div>
                    </div>
                    <div className="flex-1 flex bg-wheat-yellow2 items-center justify-center">
                        <div className="text-wheat-black ">#FFB403</div>
                    </div>
                    <div className="flex-1 flex bg-wheat-yellow3 items-center justify-center">
                        <div className="text-wheat-black ">#E89418</div>
                    </div>
                    <div className="flex-1 flex bg-wheat-yellow4 items-center justify-center">
                        <div className="text-white ">#A87600</div>
                    </div>
                    <div className="flex-1 flex bg-wheat-brown2 items-center justify-center">
                        <div className="text-white ">#4B3007</div>
                    </div>
                    <div className="flex-1 flex bg-wheat-brown1 items-center justify-center">
                        <div className="text-white ">#281A04</div>
                    </div>
                    <div className="flex-1 flex bg-wheat-black items-center justify-center">
                        <div className="text-white ">#191100</div>
                    </div>
                </div>


            </div>

            <div className="flex flex-col bg-wheat-black h-screen px-10 lg:px-32 py-20">
                <div className="text-4xl md:text-6xl font-black font-extra text-white">
                    {t('brand_p6')}
                </div>

                <div className="flex-1 grid grid-cols-3 grid-rows-2 font-large pt-5">
                    <div className="flex flex-col col-start-1 col-span-1 row-start-1 row-span-1 m-4 bg-wheat-success justify-center text-center">
                        <div className="text-white text-xl font-extra">SUCCESS</div>
                        <div className="text-white text-lg font-text">#1EBA71</div>
                    </div>

                    <div className="flex flex-col col-start-1 col-span-1 row-start-2 row-span-1 m-4 bg-wheat-danger justify-center text-center">
                        <div className="text-white text-xl font-extra">DANGER</div>
                        <div className="text-white text-lg font-text">#EB4F30</div>
                    </div>

                    <div className="flex flex-col col-start-2 col-span-1 row-start-1 row-span-1 m-4 bg-wheat-warning justify-center text-center">
                        <div className="text-wheat-black text-xl font-extra">WARNING</div>
                        <div className="text-wheat-black text-lg font-text">#FFBF00</div>
                    </div>

                    <div className="flex flex-col col-start-2 col-span-1 row-start-2 row-span-1 m-4 bg-wheat-none justify-center text-center">
                        <div className="text-white text-xl font-extra">NONE</div>
                        <div className="text-white text-lg font-text">#2B405B</div>
                    </div>

                    <div className="flex flex-col col-start-3 col-span-1 row-start-1 row-span-1 m-4 bg-white justify-center text-center">
                        <div className="text-wheat-black text-xl font-extra">WHITE</div>
                        <div className="text-wheat-black text-lg font-text">#FFFFFF</div>
                    </div>

                    <div className="flex flex-col col-start-3 col-span-1 row-start-2 row-span-1 m-4 bg-wheat-black justify-center text-center border border-white">
                        <div className="text-white text-xl font-extra">WHEAT BLACK</div>
                        <div className="text-white text-lg font-text">#191100</div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Brand
