import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import PublicRoute from './components/route/PublicRoute'
import SpecialRoute from './components/route/SpecialRoute'
import Homepage from './views/Homepage'
import Invite from './components/misc/Invite'
import Privacy from './views/Privacy'
import Disclaimer from './views/Disclaimer'
import Donate from './views/Donate'
import Guidelines from './views/Guidelines'
import Support from './views/Support'
import TermsOfUse from './views/TermsOfUse'
import Brand from './views/Brand'
import ScrollToTop from './components/route/ScrollToTop'
import Changelog from './views/Changelog'

function App() {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<PublicRoute />}>
                    <Route path='/' element={<Homepage />} />
                    <Route path='/privacy' element={<Privacy />} />
                    <Route path='/changelog' element={<Changelog />} />
                    <Route path='/disclaimer' element={<Disclaimer />} />
                    <Route path='/guidelines' element={<Guidelines />} />
                    <Route path='/support' element={<Support />} />
                    <Route path='/terms' element={<TermsOfUse />} />
                </Route>
                <Route element={<SpecialRoute />}>
                    <Route path='/brand' element={<Brand />} />
                </Route>
                <Route path='/invite' element={<Invite />} />
                <Route path='/donate' element={<Donate />} />
            </Routes>
        </Router>
    )
}

export default App;
