import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import logo from '../../assets/wheat_official.svg'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'

const NavBar = (props) => {
    const { t } = useTranslation();

    const menuItems = ['support', 'donate', 'status', 'thanks'];

    const bgColor = {
        "white": "bg-white",
        "black": "bg-wheat-black"
    };

    const textColor = {
        "white": "text-wheat-black",
        "black": "text-white"
    }

    const hoverTextColor = {
        "white": "hover:text-wheat-black",
        "black": "hover:text-white"
    }

    const hoverTextColorHover = {
        "white": "hover:text-wheat-yellow4",
        "black": "hover:text-wheat-yellow2"
    }

    const border = {
        "white": "border-white",
        "black": "border-wheat-black"
    }

    const textBgColor = {
        "white": "text-white",
        "black": "text-wheat-black"
    }

    const bgHoverColor = {
        "white": "bg-wheat-yellow4",
        "black": "bg-wheat-yellow2"
    }

    const borderHoverColor = {
        "white": "hover:border-wheat-yellow4",
        "black": "hover:border-wheat-yellow2"
    }

    const style = props.style;

    return (
        <>
            <Disclosure as="nav" className={`${bgColor[style]}`}>
                {({ open }) => (
                    <>
                        <div className='max-w-7xl mx-auto py-3 px-3 md:px-10'>
                            <div className='flex items-center h-16'>
                                <div className="inset-y-0 left-0 flex items-center md:hidden">
                                    <DisclosureButton className={`items-center justify-center w-10 p-2 rounded-md ${textColor[style]}`}>
                                        {open ? (
                                            <i className={`fa fa-times fa-2x ${textColor[style]}`} aria-hidden="true" />
                                        ) : (
                                            <i className={`fa fa-bars fa-2x ${textColor[style]}`} aria-hidden="true" />
                                        )}
                                    </DisclosureButton>
                                </div>

                                <div className='flex-1'>
                                    <div className='flex ml-5 md:ml-0 justify-between'>
                                        <Link to="/">
                                            <img
                                                className="h-8 w-auto"
                                                src={logo}
                                                alt="logo"
                                            />
                                        </Link>

                                        <div className='hidden md:flex font-text font-semibold text-base justify-center space-x-10 flex-1'>
                                            <a href={t('code') === 'en' ? "https://docs.wheatbot.xyz/" : "https://docs.wheatbot.xyz/vi"} className={`${textColor[style]} transition duration-500 ${hoverTextColorHover[style]}`}>
                                                {t('documentation')}
                                            </a>

                                            {menuItems.map(i => (
                                                <Link to={`/${i}`} className={`${textColor[style]} transition duration-500 ${hoverTextColorHover[style]}`}>
                                                    {t(i)}
                                                </Link>
                                            ))}
                                        </div>

                                        <Link to='/invite'>
                                            <div className={`border-2 ${border[style]} font-text font-semibold text-base ${textBgColor[style]} ${bgHoverColor[style]} rounded-full px-4 py-1 transition duration-500 hover:bg-transparent ${hoverTextColor[style]} ${borderHoverColor[style]}`}>
                                                {t('addtoserver')}
                                            </div>
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <DisclosurePanel>
                            <div className='pl-16 pb-5'>
                                <div className='flex flex-col font-text font-semibold text-lg justify-center'>
                                    <a href={t('code') === 'en' ? "https://docs.wheatbot.xyz/" : "https://docs.wheatbot.xyz/vi"} className={`${textColor[style]} transition duration-500 ${hoverTextColorHover[style]}`}>
                                        {t('documentation')}
                                    </a>

                                    {menuItems.map(i => (
                                        <Link to={`/${i}`} className={`${textColor[style]} transition duration-500 ${hoverTextColorHover[style]}`}>
                                            {t(i)}
                                        </Link>
                                    ))}

                                    <Link to='/invite'>
                                        <div className={`border-2 ${border[style]} font-text font-semibold text-base ${textBgColor[style]} ${bgHoverColor[style]} rounded-full px-4 py-1 transition duration-500 hover:bg-transparent ${hoverTextColor[style]} ${borderHoverColor[style]}`}>
                                            {t('addtoserver')}
                                        </div>
                                    </Link>
                                </div>
                            </div>

                        </DisclosurePanel>

                    </>
                )}
            </Disclosure>
        </>
    )
}

export default NavBar

