import Footer from "../layout/Footer";
import { Outlet } from 'react-router-dom';

const PublicRoute = () => {
    return (
        <>
            <Outlet />
            <Footer bg_color="wheat-black" border_color="wheat-yellow2" text_color="white" slo_color="wheat-yellow3" light_color="wheat-yellow1" />
        </>
    );
}

export default PublicRoute;
