import Footer from "../layout/Footer"
import { Outlet } from 'react-router-dom'

const SpecialRoute = () => {
    return (
        <>
            <Outlet />
            <Footer bg_color="wheat-brown1" border_color="wheat-yellow1" text_color="white" slo_color="wheat-yellow3" light_color="wheat-yellow2" />
        </>
    )
}

export default SpecialRoute
