import React from "react"
import { useTranslation } from "react-i18next"
import NavBar from '../components/layout/NavBar'
import { Helmet } from "react-helmet"

const Changelog = () => {
    const { t } = useTranslation()

    const data = [
        {
            codename: 'Slashera',
            color: 'bg-[#1238ae]',
            bcolor: 'border-[#1238ae]',
            date: '12/11/2022',
            name: '3',
            desc: 'Start implement Slash Commands!',
            version: [
                {
                    "name": "25FifteenBA",
                    "date": "15/03/2025",
                    "add": [
                        "<DEV> Hệ thống thống kê thực thi lệnh - Executed command statistic"
                    ],
                    "remove": []
                },
                {
                    "name": "24TwentyFiveNA",
                    "date": "25/05/2024",
                    "add": [
                        "<DEV> Sử dụng Request thay cho Message & Interation thông thường - Using Request for handle message & interation",
                        "<DEV> Sử dụng cache cho rateLimiter - Using cache for rateLimiter"
                    ],
                    "remove": []
                },
                {
                    "name": "24SevenMO",
                    "date": "07/01/2024",
                    "add": [
                        "Bổ sung tính năng Tarot ngược - Add Reversed Tarot",
                        "Hệ thống Tarot, Hoàng đạo được biên soạn lại - Update Tarot, Zodiac texts"
                    ],
                    "remove": []
                },
                {
                    "name": "24EighteenSA",
                    "date": "18/06/2024",
                    "add": [
                        "<DEV> Sử dụng SQLite cho cơ sở dữ liệu mới - Change database to SQLite"
                    ],
                    "remove": []
                },
                {
                    name: "24EighteenBA",
                    date: "18/03/2024",
                    add: [
                        "Bổ sung tính năng xem Shard của bot",
                        "Thêm cài đặt lựa chọn xem Tarot ngược theo mặc định"
                    ],
                    remove: []
                },
                {
                    name: '22TwelveMH',
                    date: '12/12/2022',
                    add: [
                        'Thêm rate-limit - Add rate limit',
                        'Đổi hệ thống tên các bản cập nhật, nâng cấp - Renew name of updates, versions'
                    ],
                    remove: []
                },
                {
                    name: '22TwelveMM',
                    date: '12/11/2022',
                    add: ['Thêm Slash Command - Add Slash Commands'],
                    remove: []
                }
            ]
        },
        {
            codename: 'Localapod',
            color: 'bg-[#d92a0b]',
            bcolor: 'border-[#d92a0b]',
            date: '28/04/2022',
            name: '2',
            desc: 'Travel around the world, fly to the space?',
            version: [
                {
                    name: '22TwelveBY',
                    date: '12/07/2022',
                    add: [
                        "Đổi ngày dương thành âm - Change from Julius Calendar's date to Vietnamese Lunar Calendar's date"
                    ],
                    remove: []
                },
                {
                    name: '22TwentyEightBN',
                    date: '28/04/2022',
                    add: [
                        'Xem hình ảnh thiên văn mỗi ngày của NASA - See astronomy picture everyday of NASA',
                        'Đa ngôn ngữ - Multi-language support',
                        'Tính năng tắt lệnh/mở lệnh đối với một kênh - Enable/Disable specific commands at specific channel'
                    ],
                    remove: []
                }
            ]
        },
        {
            codename: 'Offilease',
            color: 'bg-[#ffb403]',
            bcolor: 'border-[#ffb403]',
            date: '13/11/2021',
            name: '1',
            desc: 'Proud to be Wheat Official!',
            version: [
                {
                    name: '22TwentyMT',
                    date: '20/01/2022',
                    add: ['Các tính năng GIFS ACTIONS và EMOTIONS'],
                    remove: []
                },
                {
                    name: '22OneMT',
                    date: '01/01/2022',
                    add: ['Cập Nhật Chính sách Bảo mật', 'Thần Số Học'],
                    remove: []
                },
                {
                    name: '21ThirdteenMM',
                    date: '13/11/2021',
                    add: [
                        'Một loạt các tính năng random',
                        'Phân chia Shard',
                        'Lệnh random màu'
                    ],
                    remove: []
                }
            ]
        },
        {
            codename: 'Emeraldbot#9566',
            color: 'bg-[#0dbf43]',
            bcolor: 'border-[#0dbf43]',
            date: '09/12/2020',
            name: 'Alpha',
            desc: 'Happy born-day!',
            version: [
                {
                    name: '21ThirdteenMI',
                    date: '13/10/2021',
                    add: ['Cập nhật lại giao diện và bộ nhận diện mới của Bot'],
                    remove: ['Tính năng xem số lượng user của bot trong lệnh `info`']
                },
                {
                    name: '21FifteenCN',
                    date: '15/09/2021',
                    add: [
                        'Mở tính năng xem log phát triển bot',
                        'Cập nhật bot để phù hợp với API mới của Discord'
                    ],
                    remove: []
                },
                {
                    name: '21TwentyOneTM',
                    date: '21/08/2021',
                    add: ['Lệnh `avatar` `bug` `vote` `invite` `support`'],
                    remove: ['Lệnh `language`']
                },
                {
                    name: '21TwentySevenBY',
                    date: '27/07/2021',
                    add: ['Toàn bộ bot'],
                    remove: []
                }
            ]
        },
    ];

    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title>{t('change_mtitle')} | Wheat Discord Bot</title>
                <metadata
                    name="description"
                    content={t('change_desc')}
                />
                <link rel="canonical" href="https://www.wheatbot.xyz/changelog" />

                <meta property="og:site_name" content="Wheat Discord Bot" />
                <meta property="og:url" content="https://www.wheatbot.xyz/changelog" />
                <meta property="og:title" content="Changelog | Wheat Discord Bot" />
                <meta property="og:image" content={process.env.REACT_APP_URL_IMAGE} />
                <meta property="og:description" content="Changelog of Wheat." />
            </Helmet>

            <NavBar style="white" />

            <div className="px-10 lg:px-32 py-20 font-text bg-white ">
                <div className="text-4xl md:text-6xl font-black font-extra">{t('change_title')}</div>

                <div className="mt-16">
                    {
                        data.map(g => (
                            <>
                                {
                                    g.version.map(v => (
                                        <div className={`${g.bcolor} border-l-2 px-5 text-wheat-black my-5`}>
                                            <div className="text-base">
                                                {t('change_date')}: {v.date}
                                            </div>

                                            <div className="font-extra text-2xl my-2">
                                                {v.name}
                                            </div>

                                            <div className="text-xl font-bold">
                                                {t('change_add')}
                                            </div>

                                            {
                                                v.add.map(a => (
                                                    <div className="text-lg">
                                                        (+) {a}
                                                    </div>
                                                ))
                                            }

                                            <div className="text-xl font-bold">
                                                {t('change_remove')}
                                            </div>

                                            {
                                                v.remove.map(r => (
                                                    <div className="text-lg">
                                                        (-) {r}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                                <div className={`${g.color} p-5 text-white my-3`}>
                                    <div className="text-base">
                                        {t('change_date')}: {g.date}
                                    </div>

                                    <div className="font-extra text-3xl my-2">
                                        {g.codename}
                                    </div>

                                    <div className="font-large text-xl mb-2">
                                        {g.desc}
                                    </div>

                                    <div className="text-lg">
                                        {t('change_official')}: Wheat {g.name}
                                    </div>
                                </div>
                            </>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default Changelog
