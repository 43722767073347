import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import NavBar from '../components/layout/NavBar'
import { Helmet } from "react-helmet"
import Markdown from "react-markdown";
import viText from '../locales/vi/legal/terms.md';
import enText from '../locales/en/legal/terms.md';
import '../markdown.css';

const TermsOfUse = () => {
    const { t, i18n } = useTranslation();

    const [markdown, setMarkdown] = useState("");

    const langFile = {
        'vi': viText,
        'en': enText
    }

    useEffect(() => {
        fetch(langFile[i18n.language])
            .then((res) => res.text())
            .then((text) => setMarkdown(text));
    }, [i18n.language]);

    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title>{t('terms_mtitle')} | Wheat Discord Bot</title>
                <metadata
                    name="description"
                    content={t('terms_desc')}
                />
                <link rel="canonical" href="https://www.wheatbot.xyz/terms" />

                <meta property="og:site_name" content="Wheat Discord Bot" />
                <meta property="og:url" content="https://www.wheatbot.xyz/terms" />
                <meta property="og:title" content="Terms Of Use | Wheat Discord Bot" />
                <meta property="og:image" content={process.env.REACT_APP_URL_IMAGE} />
                <meta property="og:description" content="Latest Terms Of Use Statement of Wheat." />
            </Helmet>

            <NavBar style="white" />

            <div className="reactMarkdown px-10 lg:px-32 py-20 font-text bg-white ">
                <Markdown children={markdown} />
            </div>
        </>
    )
}

export default TermsOfUse
