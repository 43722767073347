import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEn from './locales/en/translation.json';
import translationVi from './locales/vi/translation.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationEn
            },
            vi: {
                translation: translationVi
            }
        },
        fallbackLng: 'vi',
        debug: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? true : false,
        detection: {
            order: ['localStorage', 'queryString', 'cookie'],
            cache: ['cookie']
        },
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        },

    })

export default i18n